<template>
  <div class="table-responsive">
      <table
        class="table project-list-table table-nowrap table-centered table-borderless"
      >
        <thead>
          <tr>
            <th scope="col" style="width: 100px">ID</th>
            <th scope="col">Site</th>
            <th scope="col">Taux d'occupation</th>
            <th scope="col">Taux de disponibilité</th>
            <th scope="col">Consulter</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(camp, index) in camps" v-bind:key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <h5 class="text-truncate font-size-14">
                <a href="javascript: void(0);" class="text-dark">{{ camp.name }}</a>
              </h5>
              <p class="text-muted mb-0">
                {{ camp.address }}
              </p>
            </td>
            <td><span class="badge bg-success">{{ camp.data.currentOccupationRate }}</span></td>
            <td><span class="badge bg-success">{{ camp.data.currentAvailabilityRate }}</span></td>
            <td><button class="btn btn-primary" @click="onSingleCampDetailsDisplay(camp)">Détails</button></td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
export default {
    props: {
        camps: {},
    },

    methods: {
      onSingleCampDetailsDisplay(camp){
        // this.$toast.info("TABLEAU DE BORD EN COURS DE PREPARATION");
        this.$router.push({ name: "dashboard.byCamp", params: { campId: camp.uuid } });
      }
    },
};
</script>
