<template>
  <div class="card text-center">
    <div class="card-body">
      <div class="avatar-sm mx-auto mb-4">
        <span class="avatar-title rounded-circle bg-soft bg-primary text-white font-size-16" >{{  user.first_name[0] }}.{{ user.last_name[0] }}</span
        >
      </div>
      <!---->
      <h5 class="font-size-15 mb-1">
        <a href="javascript: void(0);" class="text-dark">{{  user.first_name }} {{ user.last_name }}</a>
      </h5>
      <p class="text-muted">{{  user.role.name }}</p>
      <div>
        <a href="javascript: void(0);" class="badge bg-primary font-size-11 m-1">SONATRACH-DP IRARA</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
      user: {},
  }
};
</script>
